<!-- eslint-disable no-unused-vars -->
<template>
  <div>
    <!--Chart Card-->
    <b-card>
      <b-row>
        <b-col
          lg="3"
          xl="3"
          md="3"
        >
          <v-select
            v-model="defaultFormat"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            :searchable="false"
            label="title"
            class="mb-1"
            :options="chartTimeOptions"
            @input="selectedFilter"
          />
        </b-col>

        <b-col
          lg="9"
          xl="9"
          md="9"
          class="m-0 p-0"
        >
          <!--Hourly Section-->
          <b-row
            v-if="defaultFormat.value==='hourly'"
          >
            <b-col
              lg="4"
            >
              <b-form-datepicker
                v-model="hourlyDate"
                class="mb-1"
                placeholder="Select hourly Date"
              />
            </b-col>
            <b-col
              lg="4"
            >
              <b-form-timepicker
                v-model="startHourTime"
                locale="en"
                :show-seconds="false"
                :state="startTimer"
                placeholder="Start Hour"
                :hour12="false"
              />
            </b-col>
            <b-col
              lg="4"
            >
              <b-form-timepicker
                v-model="endHourTime"
                locale="en"
                :show-seconds="false"
                :state="endTimer"
                placeholder="End Hour"
                :hour12="false"
                @input="compareTimes"
              />
            </b-col>
          </b-row>
          <!--Multiple Days Section-->
          <b-row v-if="defaultFormat.value==='multipleDays'">
            <b-col
              lg="4"
            >
              <!-- <b-form-group>
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
            <flat-pickr
              v-model="rangeDate"
              :config="{ mode: 'range'}"
              class="form-control flat-picker bg-transparent border-0 shadow-none"
              placeholder="YYYY-MM-DD"
              @on-change="handleDateChange"
            />
          </div>
        </b-form-group> -->
              <b-form-datepicker
                v-model="multipleDayStart"
                class="mb-1"
                placeholder="Select Start Date"
              />
            </b-col>
            <b-col
              lg="4"
            >
              <b-form-datepicker
                v-model="multipleDayEnd"
                class="mb-1"
                placeholder="Select End Date"
              />
            </b-col>
          </b-row>
          <!--Month Wise Section-->
          <b-row v-if="defaultFormat.value==='monthWise'">
            <b-col
              lg="4"
            >
              <v-select
                v-model="startMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :searchable="false"
                label="Select Start Month"
                class="mb-1"
                :options="months"
              />
            </b-col>
            <b-col
              lg="4"
            >
              <v-select
                v-model="endMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :searchable="false"
                class="mb-1"
                label="Select End Month"
                :options="months"
              />
            </b-col>
            <b-col
              lg="4"
              xl="4"
              md="4"
            >
              <v-select
                v-model="monthwiseYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :searchable="false"
                class="mb-1"
                label="Select Year"
                :options="years"
              />
            </b-col>
          </b-row>
          <!--Monthly Section-->
          <b-row v-if="defaultFormat.value==='Month'">
            <b-col
              lg="4"
            >
              <v-select
                v-model="selectSingleMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :searchable="false"
                label="Select Month"
                class="mb-1"
                :options="months"
                @input="selectedSingleMonth"
              />
            </b-col>
            <b-col
              lg="4"
            >
              <v-select
                v-model="singleYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :searchable="false"
                label="Select Year"
                class="mb-1"
                :options="years"
                @input="selectedYear"
              />
            </b-col>
          </b-row>
          <!--Yearly Section-->
          <b-row v-if="defaultFormat.value==='Year'">
            <b-col
              lg="4"
            >
              <v-select
                v-model="singleYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :searchable="false"
                label="Select Year"
                class="mb-1"
                :options="years"
                @input="selectedYear"
              />
            </b-col>
          </b-row>
          <!--Year Wise Section-->
          <b-row v-if="defaultFormat.value==='yearWise'">
            <b-col
              lg="4"
            >
              <v-select
                v-model="selectStartYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :searchable="false"
                label="Select Start Year"
                class="mb-1"
                :options="years"
              />
            </b-col>
            <b-col
              lg="4"
            >
              <v-select
                v-model="selectEndYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :searchable="false"
                label="Select End Year"
                class="mb-1"
                :options="years"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!--Charts Section-->
      <b-row>
        <b-skeleton-wrapper :loading="loadData">
          <template #loading>
            <b-skeleton-img
              no-aspect
              height="400px"
              width="900px"
            />
          </template>

          <b-col
            lg="12"
            xl="12"
            md="12"
          >
            <div class="chart-wrapper">
              <div class="chart-container">
                <DeviceAnalyticChart
                  :data="chartData"
                  :options="options"
                />
              </div>
            </div>
          </b-col>
        </b-skeleton-wrapper>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormTimepicker, BFormDatepicker, BSkeletonWrapper, BSkeletonImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { $themeColors } from '@themeConfig'
import DeviceAnalyticChart from '@/components/devices-group/DeviceAnalyticChart.vue'
import months from '@/common/enums/months'
import years from '@/common/enums/years'

export default {
  components: {
    vSelect,
    BSkeletonWrapper,
    BSkeletonImg,
    BFormTimepicker,
    BFormDatepicker,
    DeviceAnalyticChart,
    BCard,
    BRow,
    BCol,
  },
  props: {
    loadData: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['get-filters', 'get-month', 'get-year'],
  data() {
    return {
      selectSingleMonth: 'January',
      monthlyYear: 2020,
      endTimer: null,
      startTimer: null,
      startHourTime: null,
      endHourTime: null,
      hourlyDate: '',
      startMonth: 'January',
      endMonth: 'December',
      singleYear: 2023,
      selectStartYear: '',
      selectEndYear: '',
      selectedMonth: null,
      monthWiseFields: {
        year: {
          label: 'Year',
        },
        month: {
          label: 'Month',
        },
      },
      months,
      years,
      defaultFormat: { title: 'Lifetime', value: 'Lifetime' },
      chartTimeOptions: [{ title: 'Monthly', value: 'Month' }, { title: 'Yearly', value: 'Year' }, { title: 'Lifetime', value: 'Lifetime' }],
      monthwiseYear: 2020,
      loadingRevenueData: false,
    }
  },

  computed: {
    chartData() {
      return {
        datasets: [
          {
            data: this.$store.getters['reports/getDataSets'],
            backgroundColor: '#1f58b5',
            borderColor: 'transparent',
            barPercentage: 0.9,
            barThickness: 30,
            maxBarThickness: 50,
          },
        ],
        labels: this.$store.getters['reports/getLabels'],
      }
    },
    options() {
      return {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: '#6e6b7b',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              ticks: {
                stepSize: this.$store.getters['reports/getStepSize'],
                min: 0,
                max: this.$store.getters['reports/getMaxValue'],
                fontColor: '#6e6b7b',
              },
            },
          ],
          x: {
            min: 0,
            max: 8,
          },
          y: {
            beginAtZero: true,
          },
        },
      }
    },
  },
  created() {
    this.$emit('get-filters', this.defaultFormat.value)
    this.$emit('get-year', this.singleYear)
    this.$emit('get-month', this.startMonth)
  },
  methods: {

    compareTimes() {
      const [h1, m1, s1] = this.startHourTime.split(':').map(Number)
      const [h2, m2, s2] = this.endHourTime.split(':').map(Number)

      const date1 = new Date()
      date1.setHours(h1, m1, s1, 0)

      const date2 = new Date()
      date2.setHours(h2, m2, s2, 0)

      if (date1.getTime() > date2.getTime()) {
        this.endTimer = false
        this.startTimer = true
      } else if (date1.getTime() < date2.getTime()) {
        this.endTimer = true
        this.startTimer = true
      } else {
        this.result = 'Time 1 is equal to Time 2'
        this.endTimer = true
        this.startTimer = true
      }
    },
    selectedSingleMonth(value) {
      this.$emit('get-month', value)
    },
    selectedYear(value) {
      this.$emit('get-year', value)
    },
    selectedFilter(value) {
      this.$emit('get-filters', value.value)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.chart-wrapper {

height: auto; /* Set the desired height for the chart container */
width: auto;
max-width: auto;
overflow-x: scroll; /* Enable scrolling when chart exceeds the container height */
}

.scrollable-chart {
height: 400px; /* Set a desired height for the chart container */
overflow-x: scroll; /* Enable scrollable behavior */
}
</style>
